// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-claim-js": () => import("./../../../src/pages/claim.js" /* webpackChunkName: "component---src-pages-claim-js" */),
  "component---src-pages-combined-home-insurance-js": () => import("./../../../src/pages/combined-home-insurance.js" /* webpackChunkName: "component---src-pages-combined-home-insurance-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-emergency-cover-js": () => import("./../../../src/pages/home-emergency-cover.js" /* webpackChunkName: "component---src-pages-home-emergency-cover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landlords-insurance-js": () => import("./../../../src/pages/landlords-insurance.js" /* webpackChunkName: "component---src-pages-landlords-insurance-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

